h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: rem(20px);
    font-family: Palatino, 'Palatino Linotype', 'Palatino LT STD', 'Book Antiqua', Georgia, serif;
    font-weight: 500;
    letter-spacing: 4px;
    text-transform: uppercase;
}

h1,
h2,
h3 {
    color: color('blue');
}

h4,
h5,
h6 {
    font-family: $lato-bold;
}

h4,
h5 {
    color: color('gold');
}

h1 {
    @include font-size('h1');
}

h2 {
    @include font-size('h2');
}

h3 {
    @include font-size('h3');
}

h4 {
    @include font-size('h4');
}

h5 {
    @include font-size('h5');
}

h6 {
    @include font-size('h6');
}

p {
    margin-top: 0;
    margin-bottom: rem(30px);
    font-size: em(20px);
}

strong {
    font-family: $lato-bold;
    font-weight: 500;
}

a {
    color: color('blue');
    text-decoration: none;

    @include link-states() {
        color: color('gold');
    }
}
