.c-nav {
    // Medium Max Desktops
    @include media-max(md) {
        display: none;
        padding: rem(80px) rem(15px) rem(15px);
        text-align: center;
        background-color: rgba(color('blue'), 0.80);
    }

    // Medium Desktops
    @include media(md) {
        display: flex;
        flex: 9;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .is-scrolling & {
        // Medium Desktops
        @include media(md) {
            //padding-bottom: rem(20px);
        }
    }

    .is-open & {
        // Medium Max Desktops
        @include media-max(md) {
            display: flex;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            overflow-y: scroll;
        }
    }
}

///
// Navigation List
///
.c-nav__list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    // Medium Max Desktops
    @include media-max(md) {
        width: 100%;
    }

    li {
        // Medium Max Desktops
        @include media-max(md) {
            margin-bottom: rem(20px);
        }

        // Medium Desktops
        @include media(md) {
            display: inline-block;
            position: relative;
            margin-left: rem(20px);
            padding-bottom: rem(30px);
            vertical-align: top;
        }

        // Large Desktops
        @include media(lg) {
            margin-left: rem(40px);
        }

        &:hover {
            .sub-menu {
                // Medium Desktops
                @include media(md) {
                    display: block;
                }
            }
        }
    }

    a {
        position: relative;
        font-family: $lato-regular;
        color: color('blue');
        text-decoration: none;
        text-transform: uppercase;
        transition: color 0.35s;

        // Medium Max Desktops
        @include media-max(md) {
            font-size: em(24px);
            color: white;
        }

        /*

        &:before {
            // Medium Desktops
            @include media(md) {
                content: '•';
                position: absolute;
                top: 100%;
                left: 50%;
                font-size: em(12px);
                color: transparent;
                text-shadow: 0 0 transparent;
                transform: translateX(-50%);
                transition: text-shadow 0.3s, color 0.3s;
                pointer-events: none;
            }
        }

        &:hover {
            &:before {
                // Medium Desktops
                @include media(md) {
                    color: white;
                    text-shadow: 10px 0 white, -10px 0 white;
                }
            }
        }

        */
    }

    .menu-quote-request {
        a {
            padding: rem(5px) rem(10px);
            border: 2px solid color('blue');

            .is-scrolling & {
                border-color: color('blue');
            }
        }
    }
}

///
// Dropdown
///
.sub-menu {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;

    // Medium Max Desktops
    @include media-max(md) {
        margin-top: rem(20px);
    }

    // Medium Desktops
    @include media(md) {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 200px;
        padding: rem(30px) rem(20px);
        background-color: white;
    }

    .is-scrolling & {
        // Medium Desktops
        @include media(md) {
            background-color: white;
        }
    }

    li {
        // Medium Desktops
        @include media(md) {
            display: block;
            margin-left: 0;
            padding-bottom: 0;
        }
    }

    li + li {
        margin-top: rem(10px);

        // Medium Desktops
        @include media(md) {
            margin-top: rem(20px);
        }
    }

    a {
        // Medium Desktops
        @include media(md) {
            color: color('blue');
        }
    }
}
