.o-col {
    float: left;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: rem(halve($base-gutter));
    padding-left: rem(halve($base-gutter));
}

.o-col-background {
    min-height: 335px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.o-col-flex {
    display: flex;
}

.o-col-flex\@6md {
    flex: 0 0 100%;

    // Medium Desktops
    @include media(md) {
        flex-basis: 50%;
    }
}

.o-col__inner {
    width: 100%;

    // Medium Desktops
    @include media(md) {
        max-width: 586px;
    }

    .o-col-flex & {
        padding: rem(30px) rem(15px);

        // Medium Desktops
        @include media(md) {
            padding: rem(60px) rem(30px);
        }
    }
}

///
// Create generic columns
///
@for $i from 1 through $base-columns {
    .o-col--#{$i} {
        width: percentage($i / $base-columns);
    }
}

///
// Create breakpoint based columns
///
@each $breakpoint in map-keys($breakpoints) {
    @include media($breakpoint) {
        @for $i from 1 through $base-columns {
            .o-col--#{$i}\@#{$breakpoint} {
                width: percentage($i / $base-columns);
            }
        }
    }
}

///
// Column Offsets
///
@if $column-offsets {
    ///
    // Create generic column offsets
    ///
    @for $i from 1 through $base-columns {
        .o-col--offset-#{$i} {
            margin-left: percentage($i / $base-columns);
        }
    }

    ///
    // Create breakpoint based column offsets
    ///
    @each $breakpoint in map-keys($breakpoints) {
        @include media($breakpoint) {
            @for $i from 1 through $base-columns {
                .o-col--offset-#{$i}\@#{$breakpoint} {
                    margin-left: percentage($i / $base-columns);
                }
            }
        }
    }
}
