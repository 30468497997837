.c-section {
    padding-top: rem(30px);
    padding-bottom: rem(30px);

    // Medium Desktops
    @include media(md) {
        padding-top: rem(60px);
        padding-bottom: rem(60px);
    }

    p {
        .c-btn {
            font-size: em(20px, 20px);
        }
    }

    ol:not([class]),
    ul:not([class]) {
        margin-top: 0;
        margin-bottom: rem(30px);
        padding-left: rem(15px);
        list-style: none;
    }

    li:not([class]) {
        position: relative;
        margin-bottom: rem(5px);
        padding-left: rem(40px);
        font-size: em(20px);

        + li:not([class]) {
            margin-top: rem(25px);
        }

        &:before {
            content: '';
            position: absolute;
            top: 3px;
            left: 0;
            width: 27px;
            height: 21px;
            background-image: url('../../dist/images/checkmark.png');
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}

///
// Main
///
.c-section--main {
    td {
        font-size: em(20px);
    }
}

///
// Intro
///
.c-section--intro {
    h1 {
        margin-bottom: 0;
        letter-spacing: 8px;
    }

    h5 {
        font-family: $lato-regular;
    }
}

///
// Fleet
///
.c-section--fleet {
    background-image: url('/app/uploads/2016/12/bg-concierge-service-960x400.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    // Medium Desktops
    @include media(md) {
        min-height: 70vh;
        background-image: url('/app/uploads/2016/12/bg-concierge-service.jpg');
        background-attachment: fixed
    }

    // Extra Large Desktops
    @include media(xl) {
        min-height: 50vh;
    }

    .o-container {
        position: relative;
    }

    .o-col:first-child {
        // Medium Desktops
        @include media(md) {
            position: relative;
            top: 40%;
            transform: translateY(40%);
        }
    }

    .parallax-image {
        // Medium Desktops
        @include media(md) {
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            transform: translate(100%, -40%);

            &.is-visible {
                animation: jetIn 4s ease-in-out forwards;
            }
        }

        // Large Desktops
        @include media(lg) {
            width: auto;
        }
    }

    @keyframes jetIn {
        0% {
            transform: translate(100%, -40%) scale(0);
        }

        100% {
            transform: translate(0, 0) scale(1);
        }
    }

    h2 {
        color: color('gold');
    }
}

///
// Concierge Service
///
.c-section--service {
    padding-top: 0;
    padding-bottom: 0;

    h2 {
        color: color('gold');
    }

    .o-col__inner {
        // Medium Desktops
        @include media(md) {
            margin-right: auto;
            margin-left: 0;
        }
    }
}

///
// Split
//
.c-section--split {
    padding-top: 0;
    padding-bottom: 0;

    .o-col-flex:first-child {
        background-color: color('grey', 'lightest');

        .o-col__inner {
            // Medium Desktops
            @include media(md) {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }

    .o-col-flex:last-child {
        color: white;
        background-color: color('grey', 'dark');
    }
}

///
// Jet Card
///
.c-section--card {
    padding-top: 0;
    padding-bottom: 0;
    background-color: color('grey', 'lightest');

    h2 {
        color: color('gold');
    }

    .o-col-flex:first-child {
        min-height: 250px;
        background-image: url('../../dist/images/jet-card.png');
        background-position: center 50px;
        background-repeat: no-repeat;
        background-size: contain;

        // Landscape
        @media (orientation: landscape) {
            min-height: 335px;
            background-position: center 50px;
        }

        // Medium Desktops
        @include media(md) {
            background-position: center 50px;
        }

        // Large Desktops
        @include media(lg) {
            background-size: auto;
        }

        // Extra Large Desktops
        @include media(xl) {
            background-position: 300px 50px;
            background-size: auto;
        }
    }
}

///
// Quick to Recover
///
.c-section--quick {
    padding-top: 0;
    padding-bottom: 0;
    background-color: color('grey', 'lightest');

    h2 {
        color: color('gold');
    }

    .o-col__inner {
        // Medium Desktops
        @include media(md) {
            margin-left: auto;
            margin-right: 0;
        }
    }
}

///
// Extra
///
.c-section--extra {
    padding-top: rem(60px);
    padding-bottom: rem(60px);
    color: white;
    background-color: black;
    background-image: url('/app/uploads/2017/02/bg-testimonials-960x400.jpg');
    background-position: center;
    background-size: cover;

    // Medium Desktops
    @include media(md) {
        padding-top: rem(100px);
        padding-bottom: rem(100px);
        background-image: url('/app/uploads/2017/02/bg-testimonials.jpg');
        background-attachment: fixed;
    }

    h2 {
        color: inherit;
    }
}

///
// Testimonials
///
.c-section--testimonials {
    padding-top: rem(60px);
    padding-bottom: rem(60px);
    color: white;
    background-color: black;
    background-image: url('/app/uploads/2018/03/bg-testimonials-new-960x400.jpg');
    background-position: center;
    background-size: cover;

    // Medium Desktops
    @include media(md) {
        padding-top: rem(100px);
        padding-bottom: rem(100px);
        background-image: url('/app/uploads/2018/03/bg-testimonials-new.jpg');
    }

    h2 {
        color: inherit;
    }
}

///
// Request Quote
///
.c-section--quote {
    position: relative;
    background-color: color('blue');

    h2 {
        color: white;
    }

    // Single Fleet
    .single-fleet & {
        display: flex;
        align-items: center;
        height: 60vh;
        overflow: hidden;

        // Landscape
        @media (orientation: landscape) and (max-width: 1023px) {
            height: 80vh;
        }

        .o-container {
            position: relative;
            z-index: 50;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 50;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.5);
        }
    }
}

.c-section__video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 30;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

///
// Map
///
.c-section--map {
    padding-top: 0;
    padding-bottom: 0;

    iframe {
        display: block;
    }
}

///
// Main
///
.c-section--main {

}

///
// Section Header
///
.c-section__header {
    position: relative;

    &:before,
    &:after {
        // Small Tablets
        @include media(sm) {
            content: '';
            position: absolute;
            top: 35%;
            z-index: z('header-lines');
            width: 100%;
            height: 1px;
            background-color: color('grey', 'section-header');
        }
    }

    &:before {
        // Small Tablets
        @include media(sm) {
            left: 0;
        }
    }

    &:after {
        // Small Tablets
        @include media(sm) {
            right: 0;
        }
    }

    h1 {
        display: inline-block;
        position: relative;
        background-color: white;

        // Medium Desktops
        @include media(md) {
            padding-left: rem(15px);
            padding-right: rem(15px);
        }
    }
}

///
// Section List
///
.c-section__list {
    margin-top: 0;
    margin-bottom: rem(30px);
    padding-left: rem(15px);
    list-style: none;

    li {
        position: relative;
        margin-bottom: rem(5px);
        padding-left: rem(20px);
        font-size: em(20px);

        &:before {
            content: '\2022';
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
