///
// Center
///
.u-text-center {
    text-align: center;
}

///
// Right
///
.u-text-right {
    text-align: right;
}

///
// Normal
///
.u-text-normal {
    text-transform: none;
}

///
// Upper
///
.u-text-upper {
    text-transform: uppercase;
}

///
// Lower
///
.u-text-lower {
    text-transform: lowercase;
}

///
// Lato Light
///
.u-text-light {
    font-family: $lato-light;
}
