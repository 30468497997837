input,
select,
textarea {
    width: 100%;
    margin-bottom: rem(20px);
    padding: rem(5px) rem(10px);
    color: $base-font-color;
    border: 1px solid color('grey', 'medium');
    border-radius: 0;
}

select {
    background-color: white;
    appearance: none;
}

textarea {
    height: 120px;
}

input[type="submit"] {
    width: auto;
    margin-bottom: 0;
    padding: rem(5px) rem(20px);
    font-size: em(14px);
    font-family: $lato-regular;
    color: white;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid white;
    transition: color 0.35s, border-color 0.35s;

    @include link-states() {
        color: color('gold');
        border-color: color('gold');
    }
}

::-webkit-input-placeholder {
    font-size: rem(14px);
    color: color('grey', 'placeholder');
    letter-spacing: 1px;
    text-transform: uppercase;
}

::-moz-placeholder {
    font-size: rem(14px);
    color: color('grey', 'placeholder');
    letter-spacing: 1px;
    text-transform: uppercase;
}

:-ms-input-placeholder {
    font-size: rem(14px);
    color: color('grey', 'placeholder');
    letter-spacing: 1px;
    text-transform: uppercase;
}

:-moz-placeholder {
    font-size: rem(14px);
    color: color('grey', 'placeholder');
    letter-spacing: 1px;
    text-transform: uppercase;
}

.departure-form {
  display: flex;
  justify-content: center;
  align-items: center;

  .c-btn--blue {
    color: #0b396b;
    border-color: #0b396b;
  }

  input {
    height: 55px;
    font-size: 25px;
    text-transform: uppercase;
    width: auto;
    margin-bottom: 0px;
  }
}
