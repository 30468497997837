body {
    margin: 0;
    font-size: $base-font-size;
    font-family: $lato-regular;
    letter-spacing: 1px;
    line-height: $base-line-height;
    color: $base-font-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.page-id-84 {
        background-image: url('/app/uploads/2017/01/sky-hero-1.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

hr {
    height: 1px;
    margin-top: rem(50px);
    margin-bottom: rem(50px);
    background-color: color('grey', 'section-header');
    border: 0;
}
