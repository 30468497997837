.c-logo {
    // Medium Max Desktops
    @include media-max(md) {
        float: left;
        max-width: 169px;
        padding-top: rem(10px);
        transition: opacity 0.35s;
    }

    // Medium Desktops
    @include media(md) {
        flex-basis: 263px;
        transition: flex 0.35s;
    }

    .is-scrolling & {
        // Medium Desktops
        @include media(md) {
            flex-basis: 169px;
        }
    }
}
