.gform_body {
    @include clearfix;
}

.gform_footer {
    clear: both;
    margin-top: rem(23px);
}

.gform_fields {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.gfield {
    position: relative;
    padding-left: 0;
    @include clearfix;

    &.gfield_error {
        input,
        select,
        textarea {
            background-color: alert('error');
        }
    }
}

.gfield_label {
    display: block;
    text-align: left;
}

.gfield_error {
    input,
    textarea,
    select {
        border-color: alert('error', 'border');
    }
}

.ginput_container_select {
    position: relative;

    &:before {
        content: '\f078';
        position: absolute;
        top: 11px;
        right: 15px;
        font-size: em(10px);
        font-family: 'FontAwesome';
        color: color('blue');
    }
}

.validation_error,
.gform_confirmation_message {
    margin-bottom: rem(30px);
    padding: rem(16px);
}

.validation_error {
    font-size: em(18px);
    color: alert('error', 'message');
    background-color: alert('error');
}

.gform_confirmation_message {
    font-size: em(18px);
    color: alert('success', 'message');
    background-color: alert('success');
}

.gfield_required {
    margin-left: rem(5px);
    color: alert('error', 'message');
}

/*
.gfield_contains_required {
    .ginput_container {
        position: relative;

        input,
        textarea {
            padding-left: rem(25px);
        }

        &:after {
            position: absolute;
            top: 5px;
            left: 10px;
            font-size: em(21px);
            color: alert('error', 'message');
            content: '\002A';
        }
    }
}
*/

.gform_hidden {
    display: none;
}

// Request Quote
//
#gform_wrapper_5 {
    padding: rem(halve($base-gutter));
    background-color: rgba(white, 0.9);

    // Medium Desktops
    @include media(md) {
        padding: rem($base-gutter);
    }

    .gform_heading {
        text-align: center;
    }
}

// Jet Card
//
#gform_wrapper_3 {
    padding: rem(halve($base-gutter));
    background-color: color('grey', 'lightest');

    // Medium Desktops
    @include media(md) {
        padding: rem($base-gutter);
    }

    .gform_heading {
        margin-bottom: rem($base-gutter);
        font-family: $lato-light;
        font-size: rem(20px);
    }
}

// Quote Request Hero
//
#gform_wrapper_6 {
    width: 100%;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding: rem(halve($base-gutter));
    background-color: rgba(white, 0.9);

    // Medium Desktops
    @include media(md) {
        padding: rem($base-gutter) rem(60px);
    }

    ::-webkit-input-placeholder {
        color: color('blue');
    }

    ::-moz-placeholder {
        color: color('blue');
    }

    :-ms-input-placeholder {
        color: color('blue');
    }

    :-moz-placeholder {
        color: color('blue');
    }

    input {
        border-color: color('blue');

        // Medium Desktops
        @include media(md) {
            margin-bottom: 0;
        }
    }

    input[type="submit"] {
        min-height: 34px;
        color: color('blue');
        background-color: white;
        border-width: 1px;

        @include link-states() {
            color: white;
            background-color: color('blue');
        }
    }

    form,
    .gform_fields {
        @include clearfix;
    }

    .gform_heading {
        text-align: center;
    }

    .gform_body,
    .gform_footer {
        // Medium Desktops
        @include media(md) {
            float: left;
        }
    }

    .gform_footer {
        // Medium Desktops
        @include media(md) {
            margin-left: rem($base-gutter);
            clear: none;
        }
    }

    .gform_fields {
        margin-left: neg(rem(halve($base-gutter)));
        margin-right: neg(rem(halve($base-gutter)));
    }

    .gfield {
        width: 100%;
        padding-left: rem(halve($base-gutter));
        padding-right: rem(halve($base-gutter));

        // Medium Desktops
        @include media(md) {
            float: left;
            width: 33.3333333333%;
        }
    }
}

.screen-reader-text {
    border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}
