.form-popup {
  display: none;
  margin-top: 45px;
  margin-bottom: 45px;

  .gform_wrapper {
    top: 0 !important;
    margin-bottom: 0 !important;
  }
}
