///
// Gravity Forms
///
.validation_message,
.gfield_description,
.ginput_counter,
.gform_validation_container {
    display: none !important;
}

///
// jQuery UI
///
.ui-datepicker {
    //padding: rem(10px);
    background-color: color('blue');

    a {
        color: white;

        @include link-states() {

        }
    }
}

.ui-datepicker-header {
    margin-top: neg(10px);
    margin-left: neg(10px);
    margin-right: neg(10px);
    padding: rem(30px);
    text-align: center;


    select {
        width: auto;
        height: auto;
        margin-right: rem(10px);
        padding: 0;
        -webkit-appearance: menulist;
        -moz-appearance: menulist;
        appearance: menulist;
    }
}

.ui-datepicker-prev,
.ui-datepicker-next {
    display: inline-block;
    width: 10px;
    height: 10px;
    cursor: pointer;

    &:hover {
        &:before {

        }
    }

    &:before {
        font-family: 'FontAwesome';
        color: white;
    }

    .ui-icon { display: none; }
}

.ui-datepicker-prev {
    float: left;

    &:before {
        content: '\f053';
    }
}

.ui-datepicker-next {
    float: right;

    &:before {
        content: '\f054';
    }
}

.ui-datepicker-calendar {
    padding: rem(10px);
    text-align: center;

    th,
    td {
        padding: rem(10px);
        color: white;
    }

    th {
        font-size: em(16px);
    }
}

///
// Gravity Forms - Form Specific
///
#gform_1, #gform_5 {
    .gfield {
        // Medium Desktops
        @include media(md) {
            width: 48%;
        }
    }

    .gfield:nth-of-type(odd) {
        // Medium Desktops
        @include media(md) {
            float: left;
        }
    }

    .gfield:nth-of-type(even) {
        // Medium Desktops
        @include media(md) {
            float: right;
        }
    }

    #field_1_7, #field_5_7 {
        // Medium Desktops
        @include media(md) {
            float: none !important;
            width: 100%;
            clear: both !important;
        }
    }
}

///
// Contact Form
///
#gform_wrapper_2,
#gform_wrapper_3 {
    input,
    select,
    textarea {
        color: color('blue') !important;
        border-color: color('blue') !important;
    }

    input[type="submit"] {
        @include link-states() {
            color: color('gold') !important;
            border-color: color('gold') !important;
        }
    }

    ::-webkit-input-placeholder {
        color: color('blue');
    }

    ::-moz-placeholder {
        color: color('blue');
    }

    :-ms-input-placeholder {
        color: color('blue');
    }

    :-moz-placeholder {
        color: color('blue');
    }
}

///
// Request a Quote Page
///
.page-id-84 {
    .c-section--main {
        #gform_wrapper_1,
        #gform_wrapper_5 {
            input,
            select,
            textarea {
                color: color('blue') !important;
                border-color: color('blue') !important;
            }

            input[type="submit"] {
                @include link-states() {
                    color: color('gold') !important;
                    border-color: color('gold') !important;
                }
            }

            ::-webkit-input-placeholder {
                color: color('blue');
            }

            ::-moz-placeholder {
                color: color('blue');
            }

            :-ms-input-placeholder {
                color: color('blue');
            }

            :-moz-placeholder {
                color: color('blue');
            }
        }
    }
}

///
// Landing Form
///
#gform_wrapper_4 {
    position: relative;
    padding: rem(halve($base-gutter));
    background-color: color('blue');

    // Medium Max Desktops
    @include media-max(md) {
        margin-bottom: rem(30px);
    }

    // Medium Desktops
    @include media(md) {
        top: neg(220px);
        margin-bottom: neg(220px);
        padding: rem($base-gutter);
    }

    .gform_heading {
        margin-bottom: rem(20px);
        text-align: center;
    }

    .gform_description {
        font-size: rem(36px);
        color: white;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
}
