.c-hero {
    position: relative;
    padding-top: rem(144px);
    padding-bottom: rem(144px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    // Medium Desktops
    @include media(md) {
        padding-top: rem(287px);
        padding-bottom: rem(287px);
    }

    .single-fleet &,
    .page-id-20 &,
    .page-id-275 & {
        // Medium Max
        @include media-max(md) {
            padding-left: rem(halve($base-gutter));
            padding-right: rem(halve($base-gutter));
        }

        // Medium Desktops
        @include media(md) {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-end;
            justify-content: center;
            height: 70vh;
            padding-top: 0;
            padding-bottom: rem(143px);
        }
    }
}

.c-hero--home {
    display: flex;
    //flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: rem(220px) 0;
    text-align: center;
    overflow: hidden;

    // Medium Desktops
    @include media(md) {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
        justify-content: center;
        height: 100vh;
        padding-top: 0;
        padding-bottom: rem(143px);
        background-attachment: fixed;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 50;
        width: 100%;
        height: 100%;
        //background: linear-gradient(to bottom, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0) 100%);
        //background-color: rgba(0,0,0,0.5);
    }
}

.c-hero__form {
    position: relative;
    z-index: 50;

    // Medium Max
    @include media-max(md) {
        padding-left: rem(halve($base-gutter));
        padding-right: rem(halve($base-gutter));
    }

    .single-fleet & {
        width: 100%;
        max-width: 1140px;
        margin-left: auto;
        margin-right: auto;
        padding: rem(halve($base-gutter));
        text-align: center;
        background-color: rgba(white, 0.9);

        // 1024 - 1199
        @media (min-width: 1024px) and (max-width: 1199px) {
            max-width: 980px;
        }

        // Medium Desktops
        @include media(md) {
            padding: rem($base-gutter) rem(60px);
        }

        ::-webkit-input-placeholder {
            color: color('blue');
        }

        ::-moz-placeholder {
            color: color('blue');
        }

        :-ms-input-placeholder {
            color: color('blue');
        }

        :-moz-placeholder {
            color: color('blue');
        }

        input {
            border-color: color('blue');

            // Medium Desktops
            @include media(md) {
                margin-bottom: 0;
            }
        }

        input[type="submit"] {
            min-height: 34px;
            color: color('blue');
            background-color: white;
            border-width: 1px;

            @include link-states() {
                color: white;
                background-color: color('blue');
            }
        }

        .gform_wrapper {
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            // Medium Desktops
            @include media(md) {
                max-width: 877px;
            }

            // Extra Large Desktops
            @include media(xl) {
                max-width: 840px;;
            }
        }

        form,
        .gform_fields {
            @include clearfix;
        }

        .gform_heading {
            text-align: center;
        }

        .gform_body,
        .gform_footer {
            // Medium Desktops
            @include media(md) {
                float: left;
            }
        }

        .gform_footer {
            // Medium Desktops
            @include media(md) {
                margin-left: rem($base-gutter);
                clear: none;
            }
        }

        .gform_fields {
            margin-left: neg(rem(halve($base-gutter)));
            margin-right: neg(rem(halve($base-gutter)));
        }

        .gfield {
            width: 100%;
            padding-left: rem(halve($base-gutter));
            padding-right: rem(halve($base-gutter));

            // Medium Desktops
            @include media(md) {
                float: left;
                width: 33.3333333333%;
            }
        }
    }
}

.c-hero__arrow {
    display: inline-block;
    position: relative;
    top: 100px;
}

.c-hero__video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 30;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
