///
// Custom fonts
///

// Example
// @font-face {
//  font-family: 'questrialregular';
//  src: url('../../dist/fonts/questrial-regular-webfont.woff2') format('woff2'),
//       url('../../dist/fonts/questrial-regular-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
// }

@font-face {
    font-family: 'latobold';
    src: url('../../dist/fonts/lato-bold-webfont.woff2') format('woff2'),
         url('../../dist/fonts/lato-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latolight';
    src: url('../../dist/fonts/lato-light-webfont.woff2') format('woff2'),
         url('../../dist/fonts/lato-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoregular';
    src: url('../../dist/fonts/lato-regular-webfont.woff2') format('woff2'),
         url('../../dist/fonts/lato-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
