.c-btn {
    display: inline-block;
    position: relative;
    font-size: em(20px);
    font-family: $lato-regular;
    color: white;
    border: 2px solid transparent;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;

    &:hover {
        &:before {
            transform: translate3d(0, 0, 0);
        }

        span {
            transform: translate3d(0, 100%, 0);
            opacity: 0;
        }
    }

    &:before {
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: rem(5px) rem(20px);
        line-height: 1.5;
        transform: translate3d(-100%, 0, 0);
        transition: transform 0.3s;
    }

    span {
        display: block;
        padding: rem(5px) rem(20px);
        vertical-align: middle;
        transition: transform 0.3s, opacity 0.3s;
    }
}

///
// White
///
.c-btn--white {
    border-color: white;

    &:before {
        color: white;
        background-color: color('gold');
    }
}

///
// Blue
///
.c-btn--blue {
    color: color('blue');
    border-color: color('blue');

    &:before {
        color: white;
        background-color: color('gold');
    }
}

///
// Large
///
.c-btn--large {
    font-size: em(30px);
    text-transform: none;

    // Medium Desktops
    @include media(md) {
        font-size: em(40px);
    }
}
