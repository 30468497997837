///
// Top
///
.c-footer__top {
    //padding-top: rem(30px);
    //padding-bottom: rem(30px);
    color: white;
    background-color: color('grey', 'dark');

    a {
        color: inherit;
        text-decoration: none;
        transition: color 0.35s;

        @include link-states() {
            color: color('gold');
        }
    }

    .o-col__inner {
        // Medium Desktops
        @include media(md) {
            margin-left: auto;
            margin-right: 0;
        }
    }

    .c-info-image {
        margin-bottom: rem(30px);

        // Medium Max Desktops
        @include media-max(md) {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .c-info {
        font-size: em(16px);
    }

    .fa {
        margin-right: rem(10px);
        font-size: em(24px);
        transition: transform 0.3s;

        &:hover {
            // Medium Desktops
            @include media(md) {
                transform: translateY(-30%);
            }
        }
    }

    .gform_wrapper {
        // Medium Max Desktops
        @include media-max(md) {
            margin-top: rem(30px);
        }
    }
}

///
// Bottom
///
.c-footer__bottom {
    padding-top: rem(10px);
    padding-bottom: rem(10px);
    background-color: color('grey', 'darkest');

    .c-footer__block {
        // Medium Max Desktops
        @include media-max(md) {
            display: none;
        }
    }
}

///
// Block
///
.c-footer__block {
    ul {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
    }

    li {
        // Medium Desktops
        @include media(md) {
            display: inline-block;
            margin-right: rem(10px);
            vertical-align: top;
        }
    }

    a {
        font-size: em(11px);
        color: white;
        text-transform: uppercase;

        @include link-states() {
            color: color('gold');
        }
    }
}

///
// Copyright
///
.c-footer__copyright {
    margin-bottom: 0;
    font-size: em(11px);
    text-transform: uppercase;

    // Medium Max Desktops
    @include media-max(md) {
        text-align: center;
    }

    // Medium Desktops
    @include media(md) {
        text-align: right;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
}
