///
// Fleet Meta
///
.c-fleet-meta {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: rem($base-gutter);
    margin-left: neg(rem(halve($base-gutter)));
    margin-right: neg(rem(halve($base-gutter)));
    padding-left: rem(halve($base-gutter));
    padding-right: rem(halve($base-gutter));

    // Medium Desktops
    @include media(md) {
        flex-flow: row wrap;
        justify-content: center;
    }
}

///
// Meta Info
///
.c-fleet-meta__info {
    //flex: 1 1 100%;
    padding-left: rem(halve($base-gutter));
    padding-right: rem(halve($base-gutter));
    font-size: rem(24px);
    font-family: $lato-light;
    color: color('gold');
    text-align: center;
    text-transform: uppercase;

    // Medium Desktops
    @include media(md) {
        //flex: 1 1 33.3333333333%;

        &:nth-child(2) {
            border-left: 1px solid color('gold');
            border-right: 1px solid color('gold');
        }
    }
}

// Images
//
.c-fleet-images {
    margin-bottom: rem($base-gutter);
    margin-left: neg(rem(halve($base-gutter)));
    margin-right: neg(rem(halve($base-gutter)));
    @include clearfix;

    figure {
        width: 100%;
        padding-left: rem(halve($base-gutter));
        padding-right: rem(halve($base-gutter));

        // Medium Desktops
        @include media(md) {
            float: left;
            width: 33.3333333333%;
        }
    }

    .wp-caption-text {
        margin-top: rem(10px);
        font-size: rem(20px);
        font-family: $lato-light;
        font-style: normal;
        text-align: center;
    }
}
