.c-testimonial {
    margin: 0;

    p {
        font-size: em(27px);
    }

    cite {
        font-size: em(18px);
        font-style: normal;

        span {
            color: white;
            padding-left: rem(10px);
            padding-right: rem(10px);
        }
    }
}
