.c-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: z('header');

    // Medium Max
    @include media-max(md) {
        height: 104px;
    }

    // Medium Desktops
    @include media(md) {
        top: neg(10px);
        margin-bottom: rem(10px);
        padding-top: rem(20px);
        transition: background-color 0.35s;
    }

    .logged-in & {
        // Medium Desktops
        @include media(md) {
            top: 32px;
        }
    }

    .is-scrolling & {
        background-color: white;
        border-bottom: 1px solid color('grey', 'section-header');
    }

    &.is-active {
        background-color: white;
    }

    .o-container-flex {
        @include clearfix;

        // Medium Desktops
        @include media-max(md) {
            flex-direction: column;
        }
    }
}
