///
// Toggle
///
.c-toggle--nav {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 15px;
    z-index: z('toggle');
    width: 32px;
    height: 32px;
    transform: translateY(100%);
    transition: transform 0.35s;

    // Medium Desktops
    @include media(md) {
        display: none;
    }

    .is-open & {
        .c-toggle__icon {
            background-color: transparent;

            &:after,
            &:before {
                top: 0;
                background-color: white;
            }

            &:after { transform: rotate(-45deg); }

            &:before { transform: rotate(45deg); }
        }
    }
}

///
// Toggle Icon
///
.c-toggle__icon {
    display: inline-block;
    position: absolute;
    top: 30%;
    right: 0;
    width: 32px;
    height: 3px;
    margin-left: auto;
    margin-right: auto;
    background-color: color('blue');
    border-radius: 5px;
    transition: transform 0.3s;

    &:after,
    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 32px;
        height: 3px;
        margin-left: auto;
        margin-right: auto;
        background-color: inherit;
        border-radius: 5px;
        transition: transform 0.3s;
    }

    &:after {
        top: 10px;
    }

    &:before {
        bottom: 10px;
    }

    .is-open & {
        background-color: white;
    }
}
